import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap, delay } from 'rxjs/operators';

import { AppGlobal, AppService } from '../app.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, Route } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn: boolean;

  // store the URL so we can redirect after logging in
  redirectUrl: string | null = null;

  constructor(
      public appService: AppService,
      private router: Router
  ) {
    this.isLoggedIn = this.appService.read('isLoggedIn');
  }

  logout(): void {
    this.isLoggedIn = false;
    this.appService.write('isLoggedIn', false);
    this.router.navigate(['/login']);
  }
}