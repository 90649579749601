import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, Route } from '@angular/router';

import { AuthService } from './auth.service';
import { AppGlobal, AppService } from '../app.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private appService: AppService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): true|UrlTree {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): true|UrlTree {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean|UrlTree {
    const url = `/${route.path}`;

    return this.checkLogin(url);
  }

  checkLogin(url: string): true|UrlTree {
    if (this.appService.read('isLoggedIn')) { return true; }

    this.appService.createModal('还没有登陆，请登录！', '提示', () => {
      // Store the attempted URL for redirecting
      this.authService.redirectUrl = url;
      this.router.navigate(['/login']);
      // Redirect to the login page
      return this.router.parseUrl('/login');
    });
  }
}