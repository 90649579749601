import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, timeout, tap, delay } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
// import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})

export class AppGlobal {
  // static domain = 'https://test01.hzhqdj.com';
  static domain = 'https://gateway.hzhqdj.com';
  // static domain = 'https://localhost';
  // static domain = 'https://192.168.31.164';
  static url = 'http://www.hzhqdj.com/';
  static cdnUrl = 'https://cdn.hzhqdj.com/';
  static cdnUrlNoBackslash = 'https://cdn.hzhqdj.com';
  static PORT = ':8080';  // 8060 测试，8080 产品
  static API: any = {

    // warehouse
    UrlGetWarehouseInfo: '/warehouse/getUserWarehouse',
    UrlGetWarehouseById: '/warehouse/getWarehouseById',
    UrlAddWarehouse: '/warehouse/addWarehouse',
    UrlDeleteWarehouse: '/warehouse/deleteWarehouse',
    UrlGetHomeInfo: '/warehouse/getHomeInfo',
    UrlGetHomeOrderNumber: '/warehouse/getHomeOrderNumber',
    UrlGetHomeOrderAmount: '/warehouse/getHomeOrderAmount',
    UrlUpdateShowWarehouse: '/warehouse/updateShowWarehouse',
    UrlGetWarehouseMessage: '/warehouse/getWarehouseMessage',
    UrlUpdateMessageStatus: '/warehouse/updateMessageStatus',
    UrlGetERentLabel: '/warehouse/getERentLabel',
    UrlAuditShopName: '/warehouse/auditShopName',
    UrlGetHomeDashChat: '/warehouse/getHomeDashChat',
    UrlGetMenuList: '/warehouse/getMenuList',
    UrlGetCityParents: '/warehouse/getCityParents',
    

    // order
    UrlUpdateOrderInfo: '/order/updateOrderInfo',

    // user
    UrlSearchUserList: '/usermanage/searchUserList',
    UrlAddUser: '/user/addUser',

    // user
    UrlLogin: '/user/login',
    UrlChangePassword: '/user/changePassword',
    UrlGetVerificationCodeForForget: '/user/getVerificationCodeForForget',
    UrlForgetPassword: '/user/forgetPassword',
    UrlCheckUserPassword: '/user/checkUserPassword',
    UrlAuditUser: '/user/auditUser',

    // order
    UrlGetShopOrders: '/order/eRent/getShopOrders',
    UrlUpdateOrderStatus: '/order/updateOrderStatus',
    UrlUpdateSplitOrderStatus: '/order/updateSplitOrderStatus',
    UrlGetSingleShopOrder: '/order/getSingleShopOrder',
    UrlGetOrderActions: '/order/getOrderActions',
    UrlOrderSplit: '/order/orderSplit',
    UrlCancelSingleOrder: '/order/cancelSingleOrder',
    UrlPrintOrder: '/order/printOrder',
    UrlUploadInvoice: '/order/uploadInvoice',
    UrlCheckInvoice: '/order/checkInvoice',
    UrlGetAftermarketPic: '/order/getAftermarketPic',


    UrlDashboardInfoForPc: '/order/getDashboardInfoForPC',
    UrlDashboardInfo: '/order/getDashboardInfo',
    UrlDashboardChartInfo: '/order/getDashboardChartInfo',
    UrlGetRefundDetails: '/order/getRefundDetails',

    // user management
    UrlGetShopUsers: '/usermanage/userList',
    UrlGetUserAccountChange: '/usermanage/getUserAccountChange',
    UrlGetEnterFactory: '/usermanage/getEnterFactory',
    UrlGetGatherUsers: '/usermanage/getGatherUsers',
    UrlGetCityFellows: '/usermanage/getCityFellows',
    UrlGetUserDesign: '/usermanage/getUserDesign',
    UrlCheckOperatorFunction: '/usermanage/checkOperatorFunction',
    UrlGetCooperation: '/usermanage/getCooperation',
    UrlGetWorkList: '/usermanage/getWorkList',
    UrlGetWorkCategory: '/usermanage/getWorkCategory',
    UrlSaveWorkCategory: '/usermanage/saveWorkCategory',
    UrlDeleteWorkCategory: '/usermanage/deleteWorkCategory',
    UrlAddWorkInfo: '/usermanage/addWorkInfo',
    UrlDeleteWorkInfo: '/usermanage/deleteWorkInfo',
    UrlUpdateShopInfo: '/usermanage/updateShopInfo',
    UrlUploadExecutorImages: '/usermanage/uploadExecutorImages',
    UrlSSOLogin: '/usermanage/ssoLogin',
    UrlGetUserRank: '/usermanage/getUserRank',
    UrlUpdateUserRank: '/usermanage/updateUserRank',
    UrlGetShopVip: '/usermanage/getShopVip',
    UrlUpdateShopVip: '/usermanage/updateShopVip',
    UrlGetShopLadders: '/usermanage/getShopLadders',
    UrlUpdateShopLadder: '/usermanage/updateShopLadder',
    UrlDeleteShopLadder: '/usermanage/deleteShopLadder',

    // goods
    UrlGetGoodsList: '/goods/getGoodsList',
    UrlUpdateGoods: '/goods/updateGoods',
    UrlShopCategoryList: '/goods/getShopCategoryList',
    UrlUpdateShopCategoryInfo: '/goods/updateShopCategoryInfo',
    UrlDeleteShopCategory: '/goods/deleteShopCategory',
    UrlGetGoodsAttr: '/goods/getGoodsAttr',
    UrlGetGoodsGallery: '/goods/getGoodsGallery',
    UrlUpdateDefaultGoodsImage: '/goods/updateDefaultGoodsImage',
    UrlGetProductList: '/goods/getProductList',
    UrlAddGoodsAttr: '/goods/addGoodsAttr',
    UrlAddGoodsAttrV1: '/goods/addGoodsAttrV1',
    UrlSaveProduct: '/goods/eRent/saveProduct',
    UrlInsertProduct: '/goods/insertProduct',
    UrlInsertErentProduct: '/goods/eRent/insertProduct',
    UrlSaveProductInfo: '/goods/saveProductInfo',
    UrlValidateProduct: '/goods/validateProduct',
    UrlDeleteGoodsAttr: '/goods/deleteGoodsAttr',
    UrlUploadGoodsImage: '/goods/uploadGoodsImage',
    UrlGetGoodsById: '/goods/getGoodsById',
    UrlGetTrashGoods: '/goods/getTrashGoods',
    UrlRecoveryTrashGoods: '/goods/recoveryTrashGoods',
    UrlDeleteTrashGoods: '/goods/deleteTrashGoods',
    UrlDeleteGoods: '/goods/deleteGoods',
    UrlUploadGoodsImageForPc: '/goods/uploadGoodsImageForPc',
    UrlUploadShopLogo: '/goods/uploadShopLogo',
    UrlUploadExecuatorForPc: '/goods/uploadExecuatorForPc',
    UrlGetGoodsVip: '/goods/getGoodsVip',
    UrlUpdateGoodsVip: '/goods/updateGoodsVip',
    UrlGetGoodsLadder: '/goods/getGoodsLadder',
    UrlUpdateGoodsLadder: '/goods/updateGoodsLadder',
    UrlDeleteGoodsLadder: '/goods/deleteGoodsLadder',
    UrlDeleteSingleGoodsLadder: '/goods/deleteSingleGoodsLadder',
    UrlSetAllGoodsVip: '/goods/setAllGoodsVip',
    UrlSetAllGoodsLadder: '/goods/setAllGoodsLadder',
    UrlUpdateGoodsAudit: '/goods/updateGoodsAudit',
    UrlGetGoodsAudit: '/goods/getGoodsAudit',
    UrlGetGoodsDesc: '/goods/getGoodsDesc',
    UrlERentSaveProduct: '/goods/eRent/saveProduct',
    UrlERentInsertDraftProduct: '/goods/eRent/insertDraftProduct',
    

    // checkVersion
    UrlCheckVersion: '/usermanage/checkVersion',
    UrlGetShopInfo: '/usermanage/getShopInfo',
    UrlCheckLoginStatus: '/usermanage/checkLoginStatus',
    UrlRecordUserInfo: '/usermanage/recordUserInfo',
    UrlChildAdminList: '/usermanage/childAdminList',
    UrlGetCityParentByCity: '/usermanage/getCityParentByCity',

    
    // comment
    UrlCommentList: '/comment/list',
    UrlCommentRecomment: '/comment/recomment',
    UrlDeleteRecomment: '/comment/delete',

    //home dash board

    UrlGetTotalOrdersAmountForShopTodayByParentId: '/warehouse/gerTotalOrdersAmountForShopTodayByParentId',
    UrlGerTotalOrdersNumberForShopTodayByParentId: '/warehouse/gerTotalOrdersNumberForShopTodayByParentId',
    UrlGerTotalOrdersAmountForShopByParentId: '/warehouse/gerTotalOrdersAmountForShopByParentId',
    UrlGerTotalOrdersNumberForShopByParentId: '/warehouse/gerTotalOrdersNumberForShopByParentId',
    UrlGetShopNotConfirmOrdersNumberByParentId: '/warehouse/getShopNotConfirmOrdersNumberByParentId',
    UrlGetGoodsNumber: '/warehouse/getGoodsNumber',

    UrlGetChatList: 'https://gateway.hzhqdj.com:8099/getChatList',
    UrlGetMessage: 'https://gateway.hzhqdj.com:8099/getMessage'
  };

}

@Injectable()
export class AppService {

  constructor(
      public http: HttpClient,
      public router: Router,
      public location: Location,
      public message: NzMessageService,
      public modalService: NzModalService,
      // public spinner: NgxSpinnerService
  ) { }

  encode(params): string {
    let str = '';
    if (params) {
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const value = params[key];
          str += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
        }
      }
      str = '?' + str.substring(0, str.length - 1);
    }
    return str;
  }

  httpGet(url, callback, params, loader: boolean = false): void {
    let userInfo: any;
    userInfo = this.read('UserInfo');

    if (!userInfo && url.indexOf('login') === -1) {
      this.toast('已退出登陆, 请重新登陆', 'error');
      return;
    }
    if (loader) {
      // this.spinner.show();
    }
    this.http.get(AppGlobal.domain + AppGlobal.PORT + url + this.encode(params))
        .pipe(
            retry(1), // retry a failed request up to 3 times
            timeout(30000),
            // catchError(this.handleError)
            catchError((error: any) => {
                if (loader) {
                  // this.spinner.hide();
                }
                this.handleError(error);
                return throwError(error);
              }
            )
        )
        .subscribe(
            (res: Response) => {
              const data = res;
              callback(data == null ? '[]' : data);
              if (loader) {
                // this.spinner.hide();
              }
            }
        );
  }

  httpPost(url, callback, params, loader: boolean = false): void {
    let userInfo: any;
    userInfo = this.read('UserInfo');
    if (!userInfo) {
      if (url.indexOf('getVerificationCodeForForget') === -1 && url.indexOf('forgetPassword') === -1) {
        this.toast('已退出登陆, 请重新登陆', 'error');
        return;
      }
    }

    if (loader) {
      // this.spinner.show();
    }
    this.http.post(AppGlobal.domain + AppGlobal.PORT + url, params)
        .pipe(
            retry(1), // retry a failed request up to 3 times
            timeout(30000),
            catchError((error: any) => {
                if (loader) {
                  // this.spinner.hide();
                }
                this.handleError(error);
                return throwError(error);
              }
            )
        )
        .subscribe(
            (res: Response) => {
              const data = res;
              callback(data == null ? '[]' : data);
              if (loader) {
                // this.spinner.hide();
              }
            }
        );
  }


  httpPurlPost(url, callback, params, loader: boolean = false): void {
    let userInfo: any;
    userInfo = this.read('UserInfo');
    if (!userInfo) {
      if (url.indexOf('getVerificationCodeForForget') === -1 && url.indexOf('forgetPassword') === -1) {
        this.toast('已退出登陆, 请重新登陆', 'error');
        return;
      }
    }

    if (loader) {
      // this.spinner.show();
    }
    this.http.post(url, params)
        .pipe(
            retry(1), // retry a failed request up to 3 times
            timeout(30000),
            catchError((error: any) => {
                if (loader) {
                  // this.spinner.hide();
                }
                this.handleError(error);
                return throwError(error);
              }
            )
        )
        .subscribe(
            (res: Response) => {
              const data = res;
              callback(data == null ? '[]' : data);
              if (loader) {
                // this.spinner.hide();
              }
            }
        );
  }


  

  httpPostHeader(url, callback, params, loader: boolean = false): void {

    const token = this.read('JWTToken');

    let userInfo: any;
    userInfo = this.read('UserInfo');

    if (!userInfo) {
      this.toast('已退出登陆, 请重新登陆', 'error');
      return;
    }

    const headers = new HttpHeaders({
      authorization: userInfo.user_id + '_' + token
    });
    const options = new HttpHeaderResponse({
      headers
    });

    if (loader) {
      // this.spinner.show();
    }
    this.http.post(AppGlobal.domain + AppGlobal.PORT + url, params, options)
        .pipe(
            retry(1), // retry a failed request up to 3 times
            timeout(30000),
            catchError((error: any) => {
                if (loader) {
                  // this.spinner.hide();
                }
                this.handleError(error);
                return throwError(error);
              }
            )
        )
        .subscribe(
            (res: Response) => {
              let data: any;
              data = res;
              if (data.code && data.code === '401') {
                  this.toast('用户登陆信息过期, 请重新登陆', 'error');
                  return false;
                }
              callback(data == null ? '[]' : data);
              if (loader) {
                // this.spinner.hide();
              }
            }
        );
  }

  httpGetAuth(url, callback, params, loader: boolean = false): void {
    const token = this.read('JWTToken');

    let userInfo: any;
    userInfo = this.read('UserInfo');

    if (!userInfo) {
      this.toast('用户登陆信息过期, 请重新登陆', 'error');
      return;
    }

    const headers = new HttpHeaders({
      authorization: userInfo.user_id + '_' + token
    });
    const options = new HttpHeaderResponse({
      headers
    });

    if (loader) {
      // this.spinner.show();
    }

    this.http.get(AppGlobal.domain + AppGlobal.PORT + url + this.encode(params), options)
        .pipe(
            retry(1), // retry a failed request up to 3 times
            timeout(30000),
            catchError((error: any) => {
                if (loader) {
                  // this.spinner.hide();
                }
                this.handleError(error);
                return throwError(error);
              }
            )
        )
        .subscribe(
            (res: Response) => {
              let data: any;
              data = res;
              if (data != null) {
                if (data.code === '401') {
                  this.toast('用户登陆信息过期, 请重新登陆', 'error');
                  return false;
                }
              }
              callback(data == null ? '[]' : data);
              if (loader) {
                // this.spinner.hide();
              }
            }
        );

  }

  getResources(url, callback): void {
      this.http.get(url)
      .subscribe(res => {
          callback(res === null ? '[]' : res);
      });
  }

  toast(msg, type?, durations?): void {
    if (!type) {
      type = 'success';
    }
    if (!durations) {
      durations = 3000;
    }
    this.message.create(type, msg, {
      nzDuration: durations
    });
  }

  createModal(content?, title?, callback?): void {
    this.modalService.create({
      nzTitle: title ? title : '提示',
      nzContent: content ? content : '',
      nzClosable: false,
      nzOkType: title === '警告' ? 'danger' : 'primary',
      nzOnOk: callback ? callback : () => { console.log('ok'); }
    });
  }
  goBack(): void {
    if ((navigator.userAgent.indexOf('MSIE') >= 0) && (navigator.userAgent.indexOf('Opera') < 0)) { // IE
      if (history.length > 0) {
        // window.history.go(-1);
        this.location.back();
      } else {
        this.router.navigate(['home']);
      }
    } else { // 非IE浏览器
      if (navigator.userAgent.indexOf('Firefox') >= 0 ||
          navigator.userAgent.indexOf('Opera') >= 0 ||
          navigator.userAgent.indexOf('Safari') >= 0 ||
          navigator.userAgent.indexOf('Chrome') >= 0 ||
          navigator.userAgent.indexOf('WebKit') >= 0) {
        if (window.history.length > 1) {
          // window.history.go(-1);
          this.location.back();
        } else {
          this.router.navigate(['home']);
        }
      } else { // 未知的浏览器
        // window.history.go(-1);
        this.location.back();
      }
    }
  }

  getFormatDate(timeStamp): string {
    const date: any = new Date(timeStamp * 1000);
    let currentDate: any;
    if (date) {
      const seperator1 = '-';
      const seperator2 = ':';
      let month: any = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = '0' + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate;
      }
      currentDate = date.getFullYear() + seperator1 + month + seperator1 + strDate + ' '
          + date.getHours() + seperator2 + date.getMinutes() + seperator2 + date.getSeconds();
    } else {
      currentDate = '';
    }
    return currentDate;
  }

  formatDate(date): string {
    const year = date.getFullYear().toString(); // 获取完整的年份(4位,1970-????)
    let month = (date.getMonth() + 1).toString(); // 获取当前月份(0-11,0代表1月)
    let day = date.getDate().toString(); // 获取当前日(1-31)
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    return year + '-' + month + '-' + day;
  }

  handleError(error: HttpErrorResponse | any): void {
    // console.log(JSON.stringify(error))
    // console.log(error);
    if (error.status === 401) {
      /*this.createModal('用户登陆信息过期, 请重新登陆', '警告', () => {
        this.clear();
        window.location.href = '/login';
      });*/
      this.modalService.create({
        nzTitle: '警告',
        nzContent: '用户登陆信息过期, 请重新登陆',
        nzClosable: false,
        nzOkType: 'danger',
        nzCancelText: null,
        nzMaskClosable: false,
        nzOnOk: () => {
          this.clear();
          window.location.href = '/login';
        }
      });
    } else if (error.status === 0) {
      this.toast('未知错误，请稍后再试', 'error');
    } else if (error === 'TimeoutError: Timeout has occurred' ||
      error.message === 'Timeout has occurred' ||
      error === 'Response with status: 0  for URL: null') {
      this.toast('网格不给力，请稍后再试!', 'error');
    } else {
      const errorInfo = error.error;
      this.toast(errorInfo.message, 'error');
    }
  }

  // 滚动到具体位置
  scrollTo(distance): void {
    if (document.body) {
      document.body.scrollTo(0, distance);
    }
    if (document.documentElement) {
      document.documentElement.scrollTo(0, distance);
    }
  }

  // 滚动条在Y轴上的滚动距离
  getScrollTop(): number {
    let scrollTop = 0;
    let bodyScrollTop = 0;
    let documentScrollTop = 0;
    if (document.body) {
      bodyScrollTop = document.body.scrollTop;
    }
    if (document.documentElement) {
      documentScrollTop = document.documentElement.scrollTop;
    }
    scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;
    return scrollTop;
  }

  // 文档的总高度
  getScrollHeight(): number {
    let scrollHeight = 0;
    let bodyScrollHeight = 0;
    let documentScrollHeight = 0;
    if (document.body) {
      bodyScrollHeight = document.body.scrollHeight;
    }
    if (document.documentElement) {
      documentScrollHeight = document.documentElement.scrollHeight;
    }
    scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
    return scrollHeight;
  }

  // 浏览器视口的高度
  getWindowHeight(): number | string {
    let windowHeight = 0;
    if (document.compatMode === 'CSS1Compat') {
      windowHeight = document.documentElement.clientHeight;
    } else {
      windowHeight = document.body.clientHeight;
    }
    return windowHeight;
  }

  getUserAgent(): string {
    const userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
    if (userAgent.indexOf('Opera') > -1) {
      return 'Opera';
    } // 判断是否Opera浏览器
    if (userAgent.indexOf('Firefox') > -1) {
      return 'FF';
    } // 判断是否Firefox浏览器
    if (userAgent.indexOf('Chrome') > -1){
      return 'Chrome';
    }
    if (userAgent.indexOf('Safari') > -1) {
      return 'Safari';
    } // 判断是否Safari浏览器
    if (userAgent.indexOf('MSIE') > -1) {
      return 'IE';
    } // 判断是否IE浏览器
  }

  write(key: string, value: any): void {
    if (value) {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  }

  read(key: string): any {
    const value: string = localStorage.getItem(key);

    if (value && value !== 'undefined' && value !== 'null') {
      return JSON.parse(value);
    }

    return null;
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}


