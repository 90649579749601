import { Component, ComponentFactoryResolver, Injector, ViewChild, TemplateRef } from '@angular/core';
import { NzIconService } from 'ng-zorro-antd/icon';
import { NzConfigService } from 'ng-zorro-antd/core/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {

  @ViewChild('nzIndicatorTpl', { static: true })
  nzIndicator: TemplateRef<void>;

  constructor(private iconService: NzIconService, private readonly nzConfigService: NzConfigService) {

    this.iconService.fetchFromIconfont({
      scriptUrl: 'https://at.alicdn.com/t/c/font_2506402_44i8130v52u.js'
    });
  }

  ngOnInit(): void {
    this.nzConfigService.set('spin', { nzIndicator: this.nzIndicator });
  }

  /*private addTitle(): void {
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .map(() => this.activatedRoute)
      .map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      .filter(route => route.outlet === 'primary')
      .mergeMap(route => route.data)
      .subscribe((event) => {
        if (event && event['title']) {
          return this.titleService.setTitle(app_title + '-' + event['title']);
        } else {
          return this.titleService.setTitle(app_title);
        }
      });
  }*/
}
